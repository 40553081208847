import { CamelCasedProperties } from 'type-fest'

import { CampaignOnboardingJobType } from '~shared/constants/campaign-onboarding'
import { ListCampaignOnboardingJobsResponseDto } from '~shared/types/campaign-onboarding.dto'

export function isJobPendingOrProcessing({
  type,
  jobs,
}: {
  type: CampaignOnboardingJobType
  jobs: CamelCasedProperties<ListCampaignOnboardingJobsResponseDto> | undefined
}) {
  return jobs
    ? jobs?.filter(
        (job) =>
          job.type === type &&
          (job.status === 'pending' || job.status === 'processing'),
      ).length > 0
    : false
}
