import {
  Alert,
  AlertIcon,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

import { CampaignOnboardingJobView } from '~/types/campaign-onboarding'

export type CampaignOnboardingJobErrorModalProps = {
  isOpen: boolean
  onClose: () => void
  job?: CampaignOnboardingJobView
}

const VALIDATION_ERROR_ALERT_MESSAGE =
  'Your CSV file contains the following errors. Please amend them and upload your CSV file again.'

const BULK_UPLOAD_TIMEOUT_ERROR_ALERT_MESSAGE =
  'Something went wrong with the upload. Please re-upload or contact us if the problem persists'

export const CampaignOnboardingJobErrorModal = ({
  isOpen,
  onClose,
  job,
}: CampaignOnboardingJobErrorModalProps) => {
  if (!job) return <></>

  const error = job.errors

  const ErrorAlert = ({
    alertMessage,
  }: {
    alertMessage: string
  }): JSX.Element => {
    return (
      <>
        <Alert
          status="error"
          textStyle="body-1"
          backgroundColor="utility.feedback.critical-subtle"
          variant="subtle"
        >
          <AlertIcon boxSize={4} />
          {alertMessage}
        </Alert>
      </>
    )
  }

  const ErrorRow = ({
    lineNumber,
    errorMessage,
  }: {
    lineNumber: number
    errorMessage: string
  }): JSX.Element => {
    return (
      <HStack alignItems="start">
        <Text textStyle="body-1" minW="6rem">
          Row {lineNumber}:
        </Text>
        <Text textStyle="body-1">{errorMessage}</Text>
      </HStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Errors</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={6}>
          <VStack spacing={5}>
            {error.type === 'validation_error' && (
              <ErrorAlert alertMessage={VALIDATION_ERROR_ALERT_MESSAGE} />
            )}
            {(error.type === 'processing_error' ||
              error.type === 'unknown_error') && (
              <ErrorAlert alertMessage={error.message} />
            )}
            {error.type === 'timeout_error' && (
              <ErrorAlert
                alertMessage={BULK_UPLOAD_TIMEOUT_ERROR_ALERT_MESSAGE}
              />
            )}
            <VStack spacing={8} alignItems="start">
              {error.data.map((e, index) => (
                <ErrorRow
                  key={index}
                  lineNumber={e.lineNumber}
                  errorMessage={e.message}
                />
              ))}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
