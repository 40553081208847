// Note: not sure why isActiveStep etc. is considered unbound
/* eslint-disable @typescript-eslint/unbound-method */
import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  FormControl,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Text,
  useSteps,
  VStack,
} from '@chakra-ui/react'
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Switch,
  Textarea,
} from '@opengovsg/design-system-react'
import _ from 'lodash'
import { useState } from 'react'
import * as react from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  useForm,
} from 'react-hook-form'
import { BiPlus } from 'react-icons/bi'
import { HiOutlineTrash } from 'react-icons/hi'

import { CreateCampaignFormState } from '../validators/CreateCampaignFormState'

import { AppPreview } from './AppPreview'
import { BeneficiaryTypeSelectionCard } from './BeneficiaryTypeSelectionCard'

import {
  DistributeStep,
  DistributeStepper,
} from '~components/DistributeStepper/DistributeStepper'

type NewCampaignFormCardProps = {
  onSubmit: (data: CreateCampaignFormState) => void
}

const placeHolderProductName = (num: number) => `Example Item ${num}`

const convertLocationNamesToArray = (
  locationNames: string | undefined,
): string[] => {
  if (!locationNames || _.isEmpty(locationNames)) {
    // empty string if no location name to have the first checkbox
    // shown
    return ['']
  }

  // split locationNamesString into an array of location names
  const locationNamesArray = locationNames
    .split('\n')
    .filter((locationName) => locationName !== '')
    .map((locationName) => locationName.trim())

  return locationNamesArray
}

const STEPPER_HEADERS = [
  { header: 'What should we call your campaign?' },
  { header: 'Do you have a list of recipients?' },
  { header: 'How will you identify your recipients?' },
  { header: 'Where are the locations for your distributions?' },
  { header: 'What items are you distributing?' },
]

export const NewCampaignFormCard = ({ onSubmit }: NewCampaignFormCardProps) => {
  const formMethods = useForm<CreateCampaignFormState>({
    mode: 'onChange',
    resolver: classValidatorResolver(CreateCampaignFormState),
    defaultValues: {
      hasQuantity: false,
      identifierType: 'nric',
      productNames: [placeHolderProductName(1)],
      isDoorToDoor: false,
      isWalkIn: 'false',
    },
  })

  const {
    setError,
    watch,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = formMethods

  const campaignName = watch('name')
  const isWalkInString = watch('isWalkIn')
  const beneficiaryType = watch('identifierType')
  const productNames = watch('productNames')
  const hasQuantity = watch('hasQuantity')
  const isDoorToDoor = watch('isDoorToDoor')
  const locationNames = watch('locationNames')
  const locationNamesArray = convertLocationNamesToArray(locationNames)
  const [shouldShowProductModal, setShouldShowProductModal] = useState(false)
  const [shouldShowLocationView, setShouldShowLocationView] = useState(false)

  const {
    activeStep,
    isActiveStep,
    isCompleteStep,
    setActiveStep,
    goToNext,
    goToPrevious,
  } = useSteps({
    index: 0,
    count: STEPPER_HEADERS.length,
  })

  // check campaign name field validation
  const handleOnCampaignNameContinueClick = async () => {
    const hasPassedValidation = await trigger('name')

    if (hasPassedValidation) {
      goToNext()
      setShouldShowProductModal(true)
    }
  }

  const handleOnWalkInSelectionContinueClick = async () => {
    const hasPassedValidation = await trigger('isWalkIn')

    if (hasPassedValidation) {
      goToNext()
      setShouldShowProductModal(false)
    }
  }

  // check campaign identifier type field validation
  const handleOnCampaignIdentifierTypeContinueClick = async () => {
    const hasPassedValidation = await trigger('identifierType')

    // Manually set the value for isDoorToDoor to be false
    // to avoid invalid campaign state during form submission.
    // NRIC and unique string campaign types cannot have
    // isDoorToDoor value to be true.
    // Reason its done here instead when user clicks out of
    // the Address box is because we want the isDoorToDoor state
    // to be persistent within the campaignIdentifierType
    // selection step.
    if (beneficiaryType !== 'address') {
      setValue('isDoorToDoor', false)
    }

    if (hasPassedValidation) {
      goToNext()
      setShouldShowLocationView(true)
    }
  }

  // check location name field validation
  const handleOnLocationNameContinueClick = async () => {
    const hasPassedValidation = await trigger('locationNames')

    // check for duplicate location names
    const locationNameArray = convertLocationNamesToArray(locationNames)
    const containsDuplicates =
      new Set(locationNameArray).size !== locationNameArray.length

    // display error if duplicate location names is detected
    if (containsDuplicates) {
      setError('locationNames', {
        type: 'custom',
        message:
          'Duplicated location names found. Please rename or remove one of the duplicates.',
      })
    }

    // check for number of locations and limit to only 5000 maximum
    const maximumLocationLimitReached = locationNameArray.length >= 5000

    // display error if number of locations input is more than 5000
    if (maximumLocationLimitReached) {
      setError('locationNames', {
        type: 'custom',
        message:
          'Maximum location limit exceeded. Please limit to 5,000 location names.',
      })
    }

    if (
      hasPassedValidation &&
      !containsDuplicates &&
      !maximumLocationLimitReached
    ) {
      setShouldShowLocationView(false)
      goToNext()
      setShouldShowProductModal(true)
    }
  }

  // check product name field validation
  const handleOnProductNameContinueClick = async () => {
    const hasPassedValidation = await trigger('productNames')

    // Trim
    const trimmedProductNames = productNames.map((productName) =>
      productName.trim(),
    )

    // check for duplicate product names
    const containsDuplicates =
      new Set(trimmedProductNames).size !== trimmedProductNames.length

    if (containsDuplicates) {
      setError('productNames', {
        type: 'custom',
        message:
          'Duplicated product names found. Please rename or remove one of the duplicates.',
      })
    }

    if (hasPassedValidation && !containsDuplicates) {
      goToNext()
    }
  }

  // submit form to create campaign
  const handleOnCreateCampaignClick = () => {
    void handleSubmit(onSubmit)()
  }

  return (
    <Box
      background="base.canvas.alt"
      display="flex"
      flexGrow={1}
      justifyContent="center"
      height="full"
    >
      <VStack spacing={14} px={16} py={12} w="full" maxW="container.xl">
        <HStack
          width="full"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={24}
        >
          <VStack
            as="form"
            flexGrow={1}
            spacing={16}
            width="full"
            minW="670px"
            maxW="65%"
          >
            <DistributeStepper index={activeStep}>
              <DistributeStep
                index={0}
                header={STEPPER_HEADERS[0].header}
                isActiveStep={isActiveStep}
                isCompleteStep={isCompleteStep}
                setActiveStep={(step: react.SetStateAction<number>) => {
                  setActiveStep(step)
                  setShouldShowProductModal(false)
                  setShouldShowLocationView(false)
                }}
              >
                <VStack alignItems="stretch" spacing={0}>
                  <FormControl isInvalid={!!errors.name} isRequired>
                    <Text textStyle="body-2" mt={4} mb={2}>
                      We recommend using short, recognisable names with the
                      first letter of each word capitalised.
                    </Text>
                    <Controller
                      render={({ field }) => (
                        <Input
                          placeholder="E.g N95 Masks Distribution"
                          {...field}
                          onFocus={() => {
                            setShouldShowLocationView(false)
                            setShouldShowProductModal(false)
                          }}
                        />
                      )}
                      name="name"
                      control={control}
                    />
                    <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                  </FormControl>
                  <HStack display="flex" justifyContent="flex-end" pt={8}>
                    <Button onClick={handleOnCampaignNameContinueClick}>
                      Continue
                    </Button>
                  </HStack>
                </VStack>
              </DistributeStep>
              <DistributeStep
                index={1}
                header={STEPPER_HEADERS[1].header}
                isActiveStep={isActiveStep}
                isCompleteStep={isCompleteStep}
                setActiveStep={(step: react.SetStateAction<number>) => {
                  setActiveStep(step)
                  setShouldShowLocationView(false)
                  setShouldShowProductModal(true)
                }}
              >
                <VStack alignItems="stretch" spacing={4}>
                  <Text textStyle="body-2" mt={4}>
                    'Recipients' refer to the people that you are distributing
                    items to.
                  </Text>
                  <Text textStyle="body-2">
                    With a list of recipients, you will be able to specify the
                    items each recipient will receive, the locations they will
                    receive these items from, and any additional information
                    relevant to your campaign.
                  </Text>
                  <FormControl isInvalid={!!errors.isWalkIn} isRequired>
                    <Controller
                      name="isWalkIn"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <RadioGroup value={value} onChange={onChange}>
                            <VStack>
                              <Radio
                                // isWalkIn value is false because there is
                                // a list of recipients
                                value="false"
                              >
                                <Text textStyle="body-1">Yes</Text>
                              </Radio>
                              <Radio
                                // isWalkIn value is true because there is
                                // no list of recipients
                                value="true"
                                mt="0px !important"
                              >
                                <Text textStyle="body-1">No</Text>
                              </Radio>
                            </VStack>
                          </RadioGroup>
                        )
                      }}
                    />

                    <FormErrorMessage>
                      {errors.isWalkIn?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <HStack display="flex" justifyContent="flex-end" pt={4}>
                    <Button variant="clear" onClick={goToPrevious}>
                      Back
                    </Button>
                    <Button onClick={handleOnWalkInSelectionContinueClick}>
                      Continue
                    </Button>
                  </HStack>
                </VStack>
              </DistributeStep>
              <DistributeStep
                index={2}
                header={STEPPER_HEADERS[2].header}
                isActiveStep={isActiveStep}
                isCompleteStep={isCompleteStep}
                setActiveStep={(step: react.SetStateAction<number>) => {
                  setActiveStep(step)
                  setShouldShowLocationView(false)
                  setShouldShowProductModal(false)
                }}
              >
                <VStack alignItems="stretch" spacing={0}>
                  <FormControl isInvalid={!!errors.identifierType} isRequired>
                    <Text textStyle="body-2" mt={4} mb={2}>
                      'Recipients' refer to the people that you are distributing
                      items to.
                    </Text>
                    <Controller
                      render={({ field }) => (
                        <HStack alignItems="stretch">
                          <Box
                            flex={1}
                            onClick={() => {
                              field.onChange('nric')
                              setShouldShowLocationView(false)
                              setShouldShowProductModal(false)
                            }}
                          >
                            <BeneficiaryTypeSelectionCard
                              beneficiaryType="nric"
                              isSelected={beneficiaryType === 'nric'}
                            />
                          </Box>
                          <Box
                            flex={1}
                            onClick={() => {
                              field.onChange('address')
                              setShouldShowLocationView(false)
                              setShouldShowProductModal(false)
                            }}
                          >
                            <BeneficiaryTypeSelectionCard
                              beneficiaryType="address"
                              isSelected={beneficiaryType === 'address'}
                            />
                          </Box>
                          <Box
                            flex={1}
                            onClick={() => {
                              field.onChange('unique_string')
                              setShouldShowLocationView(false)
                              setShouldShowProductModal(false)
                            }}
                          >
                            <BeneficiaryTypeSelectionCard
                              beneficiaryType="unique_string"
                              isSelected={beneficiaryType === 'unique_string'}
                            />
                          </Box>
                        </HStack>
                      )}
                      name="identifierType"
                      control={control}
                    />

                    {isWalkInString === 'false' &&
                      beneficiaryType === 'address' && (
                        <Flex direction="row" align="center" mt={4}>
                          <FormLabel isRequired mr="auto" mb="0">
                            Enable door-to-door distribution.
                          </FormLabel>
                          <Controller
                            render={({ field }) => (
                              <Switch
                                {...field}
                                value={undefined}
                                defaultChecked={field.value}
                                size="lg"
                                onChange={() => {
                                  field.onChange(!field.value)
                                }}
                              />
                            )}
                            name="isDoorToDoor"
                            control={control}
                          />
                        </Flex>
                      )}

                    <FormErrorMessage>
                      {errors.identifierType?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <HStack display="flex" justifyContent="flex-end" pt={8}>
                    <Button variant="clear" onClick={goToPrevious}>
                      Back
                    </Button>
                    <Button
                      onClick={handleOnCampaignIdentifierTypeContinueClick}
                    >
                      Continue
                    </Button>
                  </HStack>
                </VStack>
              </DistributeStep>
              <DistributeStep
                index={3}
                header={STEPPER_HEADERS[3].header}
                isActiveStep={isActiveStep}
                isCompleteStep={isCompleteStep}
                setActiveStep={(step: react.SetStateAction<number>) => {
                  setActiveStep(step)
                  setShouldShowLocationView(true)
                  setShouldShowProductModal(false)
                }}
              >
                <VStack alignItems="stretch" spacing={4}>
                  <FormControl isInvalid={!!errors.locationNames} isRequired>
                    <Text mt={4}>
                      Locations are places where your distributions will occur.
                      They do not need to match real addresses — you can name
                      them according to what works best for your campaign.
                    </Text>
                    <Text textStyle="body-2" mt={4} mb={2}>
                      Please list your locations below on a separate line. You
                      will be able to upload more locations later.
                    </Text>
                    <Controller
                      render={({ field }) => (
                        <Textarea
                          overflow="scroll"
                          h="10rem"
                          maxH="10rem"
                          textStyle="body-1"
                          resize="none"
                          placeholder="Location 1&#10;Location 2"
                          {...field}
                          onFocus={() => {
                            setShouldShowLocationView(true)
                            setShouldShowProductModal(false)
                          }}
                        />
                      )}
                      name="locationNames"
                      control={control}
                    />
                    <FormErrorMessage>
                      {errors.locationNames?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Alert
                    status="info"
                    textStyle="body-2"
                    backgroundColor="utility.feedback.info-subtle"
                    variant="subtle"
                  >
                    <AlertIcon boxSize={4} />
                    Each location will be given a unique location code. Your
                    distributors will be able to use these codes to join your
                    campaign on-site.
                  </Alert>
                  <HStack display="flex" justifyContent="flex-end" pt={4}>
                    <Button variant="clear" onClick={goToPrevious}>
                      Back
                    </Button>
                    <Button onClick={handleOnLocationNameContinueClick}>
                      Continue
                    </Button>
                  </HStack>
                </VStack>
              </DistributeStep>
              <DistributeStep
                index={4}
                header={STEPPER_HEADERS[4].header}
                isActiveStep={isActiveStep}
                isCompleteStep={isCompleteStep}
                setActiveStep={setActiveStep}
              >
                <VStack alignItems="stretch" spacing={4}>
                  <MultipleTextInput
                    inputs={productNames}
                    control={control}
                    name="productNames"
                    errors={errors}
                    onInputFocus={() => {
                      setShouldShowLocationView(false)
                      setShouldShowProductModal(true)
                    }}
                  />
                  <Alert
                    status="info"
                    textStyle="body-2"
                    backgroundColor="utility.feedback.info-subtle"
                    variant="subtle"
                    p={2}
                  >
                    <AlertIcon boxSize={4} />
                    Items cannot be edited after campaign is created.
                  </Alert>
                  <Divider pt={2} />
                  <Box p={3} backgroundColor="grey.50">
                    <FormControl>
                      <Flex direction="row" align="flex-start" mt={2}>
                        <VStack mr="auto" alignItems="flex-start">
                          <FormLabel isRequired mb="0">
                            Allow multiple quantities
                          </FormLabel>
                          <Text textStyle="body-1">
                            If your recipients can receive more than one of each
                            item, toggle the button to the right.
                          </Text>
                        </VStack>
                        <Controller
                          render={({ field }) => (
                            <Switch
                              {...field}
                              value={undefined}
                              defaultChecked={field.value}
                              size="lg"
                              onChange={() => {
                                field.onChange(!field.value)
                                setShouldShowProductModal(true)
                                setShouldShowLocationView(false)
                              }}
                              ml={6}
                            />
                          )}
                          name="hasQuantity"
                          control={control}
                        />
                      </Flex>
                    </FormControl>
                  </Box>
                  <HStack display="flex" justifyContent="flex-end" pt={8}>
                    <Button variant="clear" onClick={goToPrevious}>
                      Back
                    </Button>
                    <Button
                      alignSelf="flex-end"
                      onClick={handleOnProductNameContinueClick}
                    >
                      Continue
                    </Button>
                  </HStack>
                </VStack>
              </DistributeStep>
              <DistributeStep
                index={5}
                isActiveStep={isActiveStep}
                isCompleteStep={isCompleteStep}
                setActiveStep={setActiveStep}
              >
                <VStack alignItems="stretch" spacing={0}>
                  <Text textStyle="h6" mb={8}>
                    All set! You're ready to create your Distribute campaign!
                  </Text>
                  <VStack alignItems="stretch">
                    <Button onClick={handleOnCreateCampaignClick}>
                      Create Campaign
                    </Button>
                    <Button variant="clear" onClick={goToPrevious}>
                      Back
                    </Button>
                  </VStack>
                </VStack>
              </DistributeStep>
            </DistributeStepper>
          </VStack>

          <AppPreview
            shouldShowLocationView={shouldShowLocationView}
            locationNames={locationNamesArray}
            shouldShowProductModal={shouldShowProductModal}
            beneficiaryType={beneficiaryType}
            campaignName={campaignName}
            productNames={productNames}
            isHasQuantityCampaign={hasQuantity}
            setShouldShowProductModal={setShouldShowProductModal}
            isDoorToDoor={isDoorToDoor}
            isWalkIn={isWalkInString === 'true'}
          />
        </HStack>
      </VStack>
    </Box>
  )
}

type MultipleTextInputProps<T extends FieldValues> = {
  inputs: string[]
  control: Control<T>
  name: Path<T>
  errors: FieldErrors<T>

  onInputFocus?: () => void
}

const MultipleTextInput = <T extends FieldValues>({
  inputs,
  control,
  name,
  errors,
  onInputFocus,
}: MultipleTextInputProps<T>) => {
  const addInput = () => {
    const newInputs = [...inputs, placeHolderProductName(inputs.length + 1)]
    return newInputs
  }

  const removeInput = (idx: number) => {
    if (inputs.length === 1) return

    const newInputs = [...inputs]
    newInputs.splice(idx, 1)
    return newInputs
  }

  const handleInputChange = (idx: number, value: string) => {
    const newInputs = [...inputs]
    newInputs[idx] = value
    return newInputs
  }

  return (
    <VStack align="stretch" spacing={4}>
      <FormControl isInvalid={!!errors[name]}>
        <Text textStyle="body-2" mt={4} mb={2}>
          Please add your items below. We recommend using short, recognisable
          names with the first letter of each word capitalised (e.g. Food
          Rations, Canned Foods).
        </Text>
        <Controller
          render={({ field: { onChange } }) => (
            <>
              <VStack spacing={4}>
                {inputs.map((input, index) => (
                  <HStack width="100%" key={index}>
                    <Input
                      key={index}
                      value={input}
                      onChange={(e) => {
                        onChange(handleInputChange(index, e.target.value))
                      }}
                      onFocus={onInputFocus}
                    />
                    <IconButton
                      onClick={() => {
                        onChange(removeInput(index))
                      }}
                      isDisabled={inputs.length === 1}
                      aria-label="delete input"
                      icon={<HiOutlineTrash />}
                      colorScheme="critical"
                    />
                  </HStack>
                ))}
                <Button
                  onClick={() => {
                    onChange(addInput())
                  }}
                  variant="outline"
                  width="100%"
                  leftIcon={<Icon as={BiPlus} w={6} h={6} />}
                >
                  Add Item
                </Button>
              </VStack>
            </>
          )}
          control={control}
          name={name}
        />
        <FormErrorMessage py={0}>
          {errors[name]?.message as string}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  )
}
