import {
  HStack,
  Link,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import { useParams } from 'react-router-dom'

import { AdminCampaignLayout } from '~/layouts/AdminCampaignLayout'

import { BulkUploadAllocationModal } from '../components/BulkUploadAllocationModal'
import { EmptyAllocationsCard } from '../components/EmptyAllocationCard'

import { AllocationsTable } from '~components/AllocationsTable'
import { PaginationNavigation, TableSkeleton } from '~components/DataTable'
import { ExportLoadingModal } from '~components/Modal'
import { DDAction } from '~constants/datadog'
import { useCampaignOnboardingJobs } from '~features/campaign/hooks/useCampaignOnboardingJobs'
import { useIsCampaignOnboardingDisabled } from '~features/campaign/hooks/useIsCampaignOnboardingDisabled'
import { useAllocationCount } from '~hooks/useAllocationCount'
import { useAllocations } from '~hooks/useAllocations'
import { useCampaign } from '~hooks/useCampaign'
import { useExportAllocations } from '~hooks/useExportAllocations'
import { useAuth } from '~lib/auth'
import { isJobPendingOrProcessing } from '~lib/helpers/campaignOnboardingJob'
import { addCustomMonitorClick } from '~lib/helpers/monitoring'

const EXPORT_IN_PROGRESS_MODAL_HEADER = 'Export in progress'
const EXPORT_COMPLETED_SUCCESSFULLY_MODAL_HEADER = 'Export complete'
const EXPORT_FAILED_MODAL_HEADER = 'Export failed'

export const AllocationsPage = (): JSX.Element => {
  const { user } = useAuth()
  const { campaignId } = useParams() as { campaignId: string }
  const { campaign } = useCampaign(campaignId)
  const limit = 10
  const {
    allocations,
    isAllocationsLoading,
    getNextPageOfAllocations,
    isFetchingAllocationsNextPage,
    getPreviousPageOfAllocations,
    isFetchingAllocationsPreviousPage,
  } = useAllocations(campaignId, limit)
  const isCampaignOnboardingDisabled =
    useIsCampaignOnboardingDisabled(campaignId)

  const { allocationCount } = useAllocationCount(campaignId)

  const { jobs } = useCampaignOnboardingJobs(campaignId)

  // User can only upload 1 bulk allocations job per campaign and hence we
  // check the latest job to see if its processing or pending to block
  // users from uploading more than 1 job per data type.
  const isLatestAllocationBulkJobPendingOrProcessing = isJobPendingOrProcessing(
    {
      type: 'upload_allocations',
      jobs,
    },
  )

  const {
    isOpen: isBulkAddOpen,
    onOpen: onBulkAddOpen,
    onClose: onBulkAddClose,
  } = useDisclosure()

  const {
    isOpen: isExportLoadingModalOpen,
    onOpen: onExportLoadingModalOpen,
    onClose: onExportLoadingModalClose,
  } = useDisclosure()

  const { exporting, exportAllocations, abortController, hasErrorExporting } =
    useExportAllocations(campaignId, campaign)

  const data = allocations ?? []
  // boolean to represent if there is allocation data
  const hasAllocationData = data.length > 0

  const count = allocationCount ?? 0
  // boolean on whether or not to show allocation count
  // based on the total allocation count
  const shouldShowAllocationCount = count > 0

  // format allocation count
  const allocationCountString =
    count > 0
      ? `${Intl.NumberFormat('en').format(count)} ${
          count > 1 ? 'allocations' : 'allocation'
        }`
      : ''

  const handleOnExportButtonClick = async () => {
    onExportLoadingModalOpen()
    // Add custom click action to track which users exported allocation report.
    // Include user id to track back.
    addCustomMonitorClick(DDAction.EXPORT_ALLOCATIONS_REPORT, user?.id ?? '')
    await exportAllocations()
  }

  const onCancelButtonClick = () => {
    abortController.current.abort()
    onExportLoadingModalClose()
  }

  const handleOnRetryClick = async () => {
    // add custom click action to track which users exported allocation report
    // Include user id to track back.
    addCustomMonitorClick(DDAction.EXPORT_ALLOCATIONS_REPORT, user?.id ?? '')
    await exportAllocations()
  }

  return (
    <AdminCampaignLayout
      campaignId={campaignId}
      campaignName={campaign?.name}
      campaignStatus={campaign?.status}
      sidebarActiveIndex={1}
    >
      <VStack
        alignItems="stretch"
        spacing={8}
        py={12}
        px={6}
        bg="base.canvas.alt"
        minH="$100vh"
        // override the base margin
        ml="0px !important"
      >
        <HStack justifyContent="space-between" alignItems="flex-end">
          <VStack alignItems="flex-start" spacing={1}>
            <Text textStyle="h3" fontWeight="600">
              Allocations
            </Text>
            {/* Show count of allocations as long as there is allocation data. */}
            {shouldShowAllocationCount && (
              <Text textStyle="subhead-1" textColor="brand.secondary.500">
                {allocationCountString}
              </Text>
            )}
          </VStack>
          <Spacer />
          {hasAllocationData && (
            <HStack>
              <Button
                onClick={onBulkAddOpen}
                isDisabled={isCampaignOnboardingDisabled}
                isLoading={isLatestAllocationBulkJobPendingOrProcessing}
              >
                <Text textStyle="subhead-2">Bulk Upload Allocations</Text>
              </Button>
              <Button
                isLoading={exporting}
                isDisabled={data.length === 0}
                onClick={handleOnExportButtonClick}
              >
                <Text textStyle="subhead-2">Export All</Text>
              </Button>
            </HStack>
          )}
        </HStack>
        {isAllocationsLoading ? (
          <TableSkeleton />
        ) : hasAllocationData ? (
          <AllocationsTable data={data} campaign={campaign} />
        ) : (
          <EmptyAllocationsCard
            handleOnUploadMultipleAllocationsButtonClick={onBulkAddOpen}
          />
        )}
        <PaginationNavigation
          onClickNext={getNextPageOfAllocations}
          isLoadingNextPage={isFetchingAllocationsNextPage ?? false}
          onClickPrevious={getPreviousPageOfAllocations}
          isLoadingPreviousPage={isFetchingAllocationsPreviousPage ?? false}
          limit={limit}
        />
      </VStack>

      {/* Modals */}
      <BulkUploadAllocationModal
        campaignId={campaignId}
        isOpen={isBulkAddOpen}
        showDataOverrideWarning={data.length > 0}
        onOpen={onBulkAddOpen}
        onClose={onBulkAddClose}
      />
      <ExportLoadingModal
        isOpen={isExportLoadingModalOpen}
        onClose={onExportLoadingModalClose}
        header={
          exporting
            ? EXPORT_IN_PROGRESS_MODAL_HEADER
            : hasErrorExporting
              ? EXPORT_FAILED_MODAL_HEADER
              : EXPORT_COMPLETED_SUCCESSFULLY_MODAL_HEADER
        }
        content={
          exporting ? (
            <ExportInProgressModalContent />
          ) : (
            <ExportCompleteModalContent
              hasError={hasErrorExporting}
              handleOnRetryClick={handleOnRetryClick}
            />
          )
        }
        primaryButton={
          exporting ? (
            <ExportInProgressModalButton
              onCancelButtonClick={onCancelButtonClick}
            />
          ) : (
            <ExportCompletedModalButton
              onCloseButtonClick={onExportLoadingModalClose}
            />
          )
        }
      />
    </AdminCampaignLayout>
  )
}

const ExportInProgressModalContent = (): JSX.Element => {
  return (
    <VStack spacing={8} pt={10}>
      <Spinner />
      <Text>Your report is being exported and will be ready shortly.</Text>
    </VStack>
  )
}

const ExportInProgressModalButton = ({
  onCancelButtonClick,
}: {
  onCancelButtonClick: () => void
}): JSX.Element => {
  return (
    <Button variant="outline" onClick={onCancelButtonClick}>
      Cancel
    </Button>
  )
}

const ExportCompleteModalContent = ({
  hasError,
  handleOnRetryClick,
}: {
  hasError: boolean
  handleOnRetryClick: () => void
}): JSX.Element => {
  return (
    <VStack alignItems="flex-start">
      {!hasError && (
        <>
          <Text>Your Allocations report has been successfully exported.</Text>
          <Text>
            Please check your downloads folder to find your report. If the file
            is not there, you can{' '}
            <Link onClick={handleOnRetryClick}>try exporting again</Link>.
          </Text>
        </>
      )}
      {hasError && (
        <>
          <Text>Your Allocations report could not be exported.</Text>
          <Text>
            Please <Link onClick={handleOnRetryClick}>try again</Link> or
            contact us if the problem persist.
          </Text>
        </>
      )}
    </VStack>
  )
}

const ExportCompletedModalButton = ({
  onCloseButtonClick,
}: {
  onCloseButtonClick: () => void
}): JSX.Element => {
  return <Button onClick={onCloseButtonClick}>Close</Button>
}
