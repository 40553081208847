import { useMutation, useQueryClient } from '@tanstack/react-query'

import { DeleteCampaignResponseDto } from '~shared/types/campaign.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export function useDeleteCampaign({ campaignId }: { campaignId: string }) {
  const queryCache = useQueryClient()

  const callDeleteCampaignApi = async () => {
    const response = await ApiService.delete<DeleteCampaignResponseDto>(
      `/campaigns/${campaignId}`,
    )

    return response.data.isDeleted
  }

  const { isPending: isDeleteCampaignLoading, mutateAsync: deleteCampaign } =
    useMutation({
      mutationFn: callDeleteCampaignApi,
      onSuccess: async () => {
        await queryCache.invalidateQueries(queryKeys.campaigns.list)
      },
    })

  return {
    isDeleteCampaignLoading,
    deleteCampaign,
  }
}
