import { useMutation, useQueryClient } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { CamelCasedProperties } from 'type-fest'

import {
  CreateCampaignUserRequestDto,
  CreateCampaignUserResponseDto,
} from '~shared/types/campaign-user.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export function useAddCampaignAdmin({ campaignId }: { campaignId: string }) {
  const queryCache = useQueryClient()

  const callAddCampaignAdminApi = async (
    createCampaignAdminData: CamelCasedProperties<CreateCampaignUserRequestDto>,
  ) => {
    const createCampaignUserRequest: CreateCampaignUserRequestDto =
      snakecaseKeys(createCampaignAdminData)

    const response = await ApiService.post<CreateCampaignUserResponseDto>(
      `/campaigns/${campaignId}/campaign-users`,
      createCampaignUserRequest,
    )

    return response.data
  }

  const { isPending: isAddCampaignUserLoading, mutateAsync: addCampaignUser } =
    useMutation({
      mutationFn: callAddCampaignAdminApi,
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          queryKeys.campaigns.detail(campaignId)._ctx.admins,
        )
      },
    })

  return {
    isAddCampaignUserLoading,
    addCampaignUser,
  }
}
