import {
  Alert,
  AlertIcon,
  Box,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  VStack,
} from '@chakra-ui/react'
import type { AttachmentProps } from '@opengovsg/design-system-react'
import { Attachment, Button, useToast } from '@opengovsg/design-system-react'
import { useState } from 'react'

import { getApiErrorMessage, isApiError } from '~lib/helpers/api'

type CampaignOnboardingBulkUploadModalProps = {
  title: string
  description: string
  instructionsHeader: string
  instructionsContent: string
  showDataOverrideWarning: boolean
  dataOverrideWarningAlertDescription: string
  onUpload: (file: File) => Promise<void>
  isUploading: boolean
  downloadTemplateCsv: () => Promise<void>
  isOpen: boolean
  onClose: () => void
}

type FileRejections = AttachmentProps<false>['rejections']

const AttachmentUploadSection = ({
  file,
  setFile,
}: {
  file: File | undefined
  setFile: (file: File | undefined) => void
}): JSX.Element => {
  const [rejections, setRejections] = useState<FileRejections>([])

  return (
    <VStack alignItems="inherit" ml={3}>
      <Text textStyle="subhead-1">
        Export your CSV file and upload it below
      </Text>
      <Attachment
        maxSize={15000000}
        onChange={setFile}
        name="attachment"
        value={file}
        showFileSize={true}
        rejections={rejections}
        onRejection={setRejections}
        multiple={false}
        // only accept csv files
        accept={'text/csv'}
      />
    </VStack>
  )
}

const DownloadTemplateCsvLink = ({
  handleOnClickDownloadTemplateCsv,
}: {
  handleOnClickDownloadTemplateCsv: () => void
}): JSX.Element => {
  return (
    <>
      <Link onClick={handleOnClickDownloadTemplateCsv} ml={3}>
        Download your CSV template
      </Link>
    </>
  )
}

const DataOverrideWarningAlert = ({
  description,
}: {
  description: string
}): JSX.Element => {
  return (
    <Alert
      status="warning"
      textStyle="body-2"
      backgroundColor="utility.feedback.warning-subtle"
      variant="subtle"
      w="36rem"
    >
      <AlertIcon boxSize={4} />
      {description}
    </Alert>
  )
}

const Instructions = ({
  header,
  content,
}: {
  header: string
  content: string
}): JSX.Element => {
  return (
    <VStack alignItems="flex-start" ml={3}>
      <Text>{header}</Text>
      <Text textStyle="body-2">{content}</Text>
    </VStack>
  )
}

export const CampaignOnboardingBulkUploadModal = ({
  title,
  description,
  instructionsHeader,
  instructionsContent,
  showDataOverrideWarning,
  dataOverrideWarningAlertDescription,
  onUpload,
  isUploading,
  downloadTemplateCsv,
  isOpen,
  onClose,
}: CampaignOnboardingBulkUploadModalProps) => {
  const [file, setFile] = useState<File | undefined>(undefined)
  const toast = useToast()

  const handleSubmit = async (file: File | undefined) => {
    if (!file) return
    try {
      await onUpload(file)
      toast({
        title: 'Upload in progress.',
        description:
          'Your data is being processed. Please check back shortly for updates.',
        status: 'loading',
        duration: 3000, // 3 seconds
      })
      onClose()
    } catch (e) {
      let title: string
      let description: string

      // Any error returned from our server.
      if (isApiError(e)) {
        title = 'Upload failed!'
        description = getApiErrorMessage(e)
      }
      // Need to check if error is of type Error because
      // error is implicilty of type unknown.
      else if (e instanceof Error) {
        title = 'Upload failed'
        description = e.message
      }
      // Generic error.
      else {
        title = 'Something went wrong'
        description =
          'Please try your last action again. If the issue persists, please contact DistributeSG team for assistance.'
      }

      toast({
        title,
        description,
        status: 'error',
      })
      onClose()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="2xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent px={2} maxHeight="max-content">
        {/* 
          TODO: Figure out why the custom style tokens are 
          not properly used by ModalHeader component 
        */}
        <ModalHeader mt={4}>
          <Text textStyle="h4" color="base.content.strong">
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton mt={4} mr={4} size="sm" color="base.content.strong" />
        <ModalBody>
          <VStack alignItems="stretch" spacing={6}>
            <Box>
              <Text textStyle="body-1" mb={2}>
                {description}
              </Text>
              {showDataOverrideWarning && (
                <DataOverrideWarningAlert
                  description={dataOverrideWarningAlertDescription}
                />
              )}
            </Box>
            <VStack alignItems="stretch">
              <OrderedList spacing={7}>
                <ListItem textStyle="subhead-1">
                  <DownloadTemplateCsvLink
                    handleOnClickDownloadTemplateCsv={downloadTemplateCsv}
                  />
                </ListItem>
                <ListItem textStyle="subhead-1">
                  <Instructions
                    header={instructionsHeader}
                    content={instructionsContent}
                  />
                </ListItem>
                <ListItem textStyle="subhead-1">
                  <AttachmentUploadSection file={file} setFile={setFile} />
                </ListItem>
              </OrderedList>
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter pb={8}>
          <Button onClick={() => handleSubmit(file)} isLoading={isUploading}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
