import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react'
import _ from 'lodash'
import { Fragment, ReactNode } from 'react'
import { HiOutlineLocationMarker, HiOutlineUser } from 'react-icons/hi'

import { formatAsAddressString } from '~shared/utils/address'

import { CampaignView } from '~/types/campaign'
import { DistributionView } from '~/types/distribution'

import { reprintDateTime } from '~utils/date'

type DistributionDrawerProps = {
  isOpen: boolean
  onClose: () => void
  distribution: DistributionView | undefined
  campaign: CampaignView | undefined
}

export default function DistributionDrawer({
  isOpen,
  onClose,
  distribution,
  campaign,
}: DistributionDrawerProps) {
  const textRemark = distribution?.remarks.find((r) => r.type === 'text')?.body
  const imageRemarkUrls = distribution?.remarks
    .filter((r) => r.type === 'image')
    .map((r) => r.body)

  const products = campaign?.products ?? []

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent minWidth="600px">
        <DrawerCloseButton />
        <DrawerHeader px={10} pb={8} pt={12}>
          <VStack alignItems="stretch" spacing={2}>
            <VStack
              alignItems="stretch"
              textStyle="caption-2"
              color="base.content.default"
              spacing={2}
            >
              <Text> Distribution ID: {distribution?.id} </Text>
              {campaign?.identifierType === 'nric' && (
                <Text textStyle="h6" color="base.content.strong">
                  {distribution?.identifier}
                </Text>
              )}
              {campaign?.identifierType === 'unique_string' && (
                <Text textStyle="h6" color="base.content.strong">
                  {distribution?.uniqueStringIdentifier}
                </Text>
              )}
              {campaign?.identifierType === 'address' && (
                <Text textStyle="h6" color="base.content.strong">
                  {formatAsAddressString({
                    postalCode: distribution?.postalCode,
                    floor: distribution?.floor,
                    unit: distribution?.unit,
                    streetName: distribution?.streetName,
                  })}
                </Text>
              )}
            </VStack>
            <VStack alignItems="stretch" spacing={1}>
              <HStack>
                <HiOutlineLocationMarker size={16} />
                <Text textStyle="subhead-2">{distribution?.locationName}</Text>
              </HStack>
              <HStack>
                <HiOutlineUser size={16} />
                <Text textStyle="subhead-2">
                  {distribution?.distributorIdentifier}
                </Text>
              </HStack>
            </VStack>
          </VStack>
        </DrawerHeader>
        <Divider />
        <DrawerBody px={10} py={8}>
          <VStack align="stretch" spacing={12}>
            <Grid
              gridRowGap={2}
              gridColumnGap={4}
              gridTemplateColumns="132px 1fr"
            >
              <SectionHeader>Distribution Details</SectionHeader>
              <DistributionFieldLabel>Distribution ID</DistributionFieldLabel>
              <DistributionFieldValue>
                {distribution?.id}
              </DistributionFieldValue>

              <DistributionFieldLabel>Recipient ID</DistributionFieldLabel>
              <DistributionFieldValue>
                {distribution?.identifier}
              </DistributionFieldValue>

              <DistributionFieldLabel>Location</DistributionFieldLabel>
              <DistributionFieldValue>
                {distribution?.locationName}
              </DistributionFieldValue>

              <DistributionFieldLabel>Distributed By</DistributionFieldLabel>
              <DistributionFieldValue>
                {distribution?.distributorIdentifier}
              </DistributionFieldValue>

              <DistributionFieldLabel>Distribution Time</DistributionFieldLabel>
              <DistributionFieldValue>
                {distribution?.createdAt &&
                  reprintDateTime(distribution.createdAt)}
              </DistributionFieldValue>

              <DistributionFieldLabel>Last Updated Time</DistributionFieldLabel>
              <DistributionFieldValue>
                {distribution?.updatedAt &&
                  reprintDateTime(distribution.updatedAt)}
              </DistributionFieldValue>
            </Grid>

            <VStack alignItems="stretch">
              <SectionHeader>Allocation Details</SectionHeader>
              {distribution?.allocation ? (
                <Grid
                  gridRowGap={2}
                  gridColumnGap={4}
                  gridTemplateColumns="132px 1fr"
                >
                  <DistributionFieldLabel>
                    Allocated Location
                  </DistributionFieldLabel>
                  <DistributionFieldValue>
                    {distribution.allocation.location_name}
                  </DistributionFieldValue>
                  {Object.keys(distribution.allocation.metadata).map(
                    (metadataKey) => (
                      <Fragment key={metadataKey}>
                        <DistributionFieldLabel>
                          {metadataKey}
                        </DistributionFieldLabel>
                        <DistributionFieldValue>
                          {distribution.allocation?.metadata &&
                            (distribution.allocation.metadata[
                              metadataKey
                            ] as string)}
                        </DistributionFieldValue>
                      </Fragment>
                    ),
                  )}
                </Grid>
              ) : (
                <Text textStyle="body-2" color="base.content.strong">
                  Recipient has no allocations.
                </Text>
              )}
            </VStack>

            <Grid
              gridRowGap={3}
              gridColumnGap={4}
              gridTemplateColumns="132px 0.3fr 0.5fr"
            >
              <SectionHeader colSpan={3}>Product Details</SectionHeader>
              <DistributionFieldValue fontWeight="500">
                Product Name
              </DistributionFieldValue>
              <DistributionFieldValue fontWeight="500">
                Distributed Qty
              </DistributionFieldValue>
              <DistributionFieldValue fontWeight="500">
                Allocated Qty
              </DistributionFieldValue>
              {products.map((p) => {
                const distributionProduct = distribution?.products.find(
                  (dp) => dp.id === p.id,
                )
                const distributedQty = distributionProduct
                  ? (distributionProduct.quantity ?? 1)
                  : 0
                const allocationProduct =
                  distribution?.allocation?.products.find(
                    (ap) => ap.id === p.id,
                  )
                const allocatedQty = allocationProduct
                  ? (allocationProduct.quantity ?? 1)
                  : 0

                return (
                  <Fragment key={p.id}>
                    <DistributionFieldValue>{p.name}</DistributionFieldValue>
                    <DistributionFieldValue>
                      {distributedQty}
                    </DistributionFieldValue>
                    <DistributionFieldValue>
                      {allocatedQty}
                    </DistributionFieldValue>
                  </Fragment>
                )
              })}
            </Grid>

            <VStack alignItems="stretch">
              <SectionHeader>Remarks</SectionHeader>
              {!_.isEmpty(textRemark) || !_.isEmpty(imageRemarkUrls) ? (
                <VStack alignItems="stretch" spacing={8}>
                  {!_.isEmpty(textRemark) && (
                    <Text textStyle="body-2" color="base.content.strong">
                      {textRemark}
                    </Text>
                  )}
                  {!!imageRemarkUrls && !_.isEmpty(imageRemarkUrls) && (
                    <HStack>
                      {imageRemarkUrls.map((url) => (
                        <Box boxSize="sm" key={url}>
                          <Image src={url} />
                        </Box>
                      ))}
                    </HStack>
                  )}
                </VStack>
              ) : (
                <Text textStyle="body-2" color="base.content.strong">
                  There are no remarks for this distribution.
                </Text>
              )}
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const SectionHeader = ({
  children,
  colSpan = 2, // default to take up 2 columns worth of space, but allow override
}: {
  children: ReactNode
  colSpan?: number
}) => (
  <Text
    as={GridItem}
    textStyle="subhead-3"
    color="base.content.strong"
    colSpan={colSpan}
    pb={2}
  >
    {children}
  </Text>
)

const DistributionFieldLabel = ({ children }: { children: ReactNode }) => (
  <Text as={GridItem} textStyle="body-2" color="base.content.medium">
    {children}
  </Text>
)

const DistributionFieldValue = ({
  children,
  ...textStyleProps
}: {
  children: ReactNode
} & TextProps) => (
  <Text
    as={GridItem}
    textStyle="body-2"
    color="base.content.strong"
    whiteSpace="pre-line"
    overflowWrap="anywhere"
    {...textStyleProps}
  >
    {children}
  </Text>
)
