import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CamelCasedProperties } from 'type-fest'

import { UpdateLocationRequestDto } from '~shared/types/location.dto'

import { updateLocationById } from '../api/updateLocation'

import { queryKeys } from '~lib/constants/query'

type UpdateLocationType = {
  locationId: string
  updateLocationData: CamelCasedProperties<UpdateLocationRequestDto>
}

export const useUpdateLocation = (campaignId: string) => {
  const queryCache = useQueryClient()

  const { mutateAsync: updateLocation, isPending: isUpdatingLocation } =
    useMutation({
      mutationFn: (data: UpdateLocationType) =>
        updateLocationById(
          campaignId,
          data.locationId,
          data.updateLocationData,
        ),
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          // somehow invalidating the individual location detail makes UI update slower than this
          queryKeys.campaigns.detail(campaignId)._ctx.locations,
        )
      },
    })

  return {
    updateLocation,
    isUpdatingLocation,
  }
}
