import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { CamelCasedProperties } from 'type-fest'

import {
  UpdateCampaignRequestDto,
  UpdateCampaignResponseDto,
} from '~shared/types/campaign.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export function useUpdateCampaign({ campaignId }: { campaignId: string }) {
  const queryCache = useQueryClient()

  const callUpdateCampaignApi = async (
    updateCampaignData: CamelCasedProperties<UpdateCampaignRequestDto>,
  ) => {
    const updateCampaignRequest: UpdateCampaignRequestDto =
      snakecaseKeys(updateCampaignData)

    const response = await ApiService.put<UpdateCampaignResponseDto>(
      `/campaigns/${campaignId}`,
      updateCampaignRequest,
    )

    return response.data
      ? camelcaseKeys(response.data, { deep: false })
      : response.data
  }

  const { isPending: isUpdateCampaignLoading, mutateAsync: updateCampaign } =
    useMutation({
      mutationFn: callUpdateCampaignApi,
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          queryKeys.campaigns.detail(campaignId),
        )
      },
    })

  return {
    isUpdateCampaignLoading,
    updateCampaign,
  }
}
