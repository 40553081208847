import { useMutation, useQueryClient } from '@tanstack/react-query'

import { deleteDistributorById } from '../api/deleteDistributor'

import { queryKeys } from '~lib/constants/query'

export const useDeleteDistributor = (campaignId: string) => {
  const queryCache = useQueryClient()

  const { mutateAsync: deleteDistributor, isPending: isDeletingDistributor } =
    useMutation({
      mutationFn: (distributorId: number) =>
        deleteDistributorById(campaignId, distributorId),
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          queryKeys.campaigns.detail(campaignId)._ctx.distributors,
        )
      },
    })

  return {
    deleteDistributor,
    isDeletingDistributor,
  }
}
