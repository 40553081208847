import {
  Box,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button, Checkbox, useToast } from '@opengovsg/design-system-react'
import { useState } from 'react'
import { BiSolidErrorCircle } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import { useDeleteCampaign } from '../hooks/useDeleteCampaign'

import { routes } from '~constants/routes'
import { getApiErrorMessage, isApiError } from '~lib/helpers/api'

const ErrorAlert = () => {
  return (
    <HStack>
      <Icon
        as={BiSolidErrorCircle}
        fontSize="1rem"
        color="utility.feedback.critical"
      />
      <Text textStyle="body-2" color="utility.feedback.critical">
        Please check all boxes to remove all access to campaign data.
      </Text>
    </HStack>
  )
}

export const RemoveCampaignModal = ({
  campaignId,
  isOpen,
  onClose,
}: {
  campaignId: string
  isOpen: boolean
  onClose: () => void
}): JSX.Element => {
  const [firstCheckboxChecked, setFirstCheckboxChecked] =
    useState<boolean>(false)
  const [secondCheckboxChecked, setSecondCheckboxChecked] =
    useState<boolean>(false)
  const [thirdCheckboxChecked, setThirdCheckboxChecked] =
    useState<boolean>(false)
  const [shouldShowError, setShouldShowError] = useState<boolean>(false)

  const navigate = useNavigate()

  const toast = useToast()

  const { deleteCampaign, isDeleteCampaignLoading } = useDeleteCampaign({
    campaignId,
  })

  const handleOnCancelClick = () => {
    // reset the modal's state
    setFirstCheckboxChecked(false)
    setSecondCheckboxChecked(false)
    setThirdCheckboxChecked(false)
    setShouldShowError(false)
    onClose()
  }

  const handleOnRemoveConfirmationButtonClick = async () => {
    if (
      !firstCheckboxChecked ||
      !secondCheckboxChecked ||
      !thirdCheckboxChecked
    ) {
      setShouldShowError(true)
      return
    }

    setShouldShowError(false)

    // Invoke function to delete all campaign users.
    try {
      await deleteCampaign()

      // Route user back to the home dashboard page.
      navigate(routes.index)

      // Show toast to feedback to user that campaign has been successfully removed.
      toast({
        title: 'Successfully removed',
        description:
          'You and your collaborators will not have access to this campaign and your distributors will not be able to access the campaign through the mobile app.',
        status: 'success',
        duration: 10000, // 10 seconds
        isClosable: true,
      })
    } catch (e) {
      if (isApiError(e)) {
        toast({
          title: `Unable to remove access to campaign data. Please try again.`,
          description: getApiErrorMessage(e),
          status: 'error',
        })
      } else {
        toast({
          title: `Unable to remove access to campaign data. Please try again.`,
          description:
            'Please try again later. If the problem persists, please contact the DistributeSG team.',
          status: 'error',
        })
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={6}>
          <Text textStyle="h4">Remove all access to campaign data?</Text>
        </ModalHeader>

        <ModalBody>
          <VStack alignItems="flex-start" w="100%" spacing={4}>
            <Text textStyle="body-1">
              This means you and your campaign collaborators will no longer have
              access to this campaign. Similarly, your distributors will not be
              able to access the campaign through the DistributeSG mobile app.
              Please note that while access is removed, the data is not deleted
              and will continue to be handled in accordance with our data
              protection standards.
            </Text>
            <Text>Are you sure you want to continue?</Text>
            <Box>
              <Checkbox
                isChecked={firstCheckboxChecked}
                onChange={() => setFirstCheckboxChecked(!firstCheckboxChecked)}
                // remove the outline when the checkbox is focused
                _focusWithin={{ outline: 'none' }}
              >
                I understand that my campaign collaborators and I will no longer
                be able to access this campaign's data.
              </Checkbox>
              <Checkbox
                isChecked={secondCheckboxChecked}
                onChange={() =>
                  setSecondCheckboxChecked(!secondCheckboxChecked)
                }
                // remove the outline when the checkbox is focused
                _focusWithin={{ outline: 'none' }}
              >
                I understand that my distributors will no longer be able to
                access this campaign through the DistributeSG mobile app.
              </Checkbox>
              <Checkbox
                isChecked={thirdCheckboxChecked}
                onChange={() => setThirdCheckboxChecked(!thirdCheckboxChecked)}
                // remove the outline when the checkbox is focused
                _focusWithin={{ outline: 'none' }}
              >
                I understand that campaign data will be retained in
                DistributeSG's database in accordance with our data retention
                policies.
              </Checkbox>
              {shouldShowError && <ErrorAlert />}
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter pb={8} pt={6}>
          <HStack>
            <Button
              variant="clear"
              size="md"
              color="base.content.strong"
              onClick={handleOnCancelClick}
            >
              Cancel
            </Button>
            <Button
              size="md"
              colorScheme="red"
              onClick={handleOnRemoveConfirmationButtonClick}
              isLoading={isDeleteCampaignLoading}
            >
              Remove access
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
