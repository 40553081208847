import { useMutation, useQueryClient } from '@tanstack/react-query'

import { DeleteCampaignUserResponseDto } from '~shared/types/campaign-user.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export function useDeleteCampaignAdmin({ campaignId }: { campaignId: string }) {
  const queryCache = useQueryClient()

  const callDeleteCampaignAdminApi = async (campaignUserId: string) => {
    const response = await ApiService.delete<DeleteCampaignUserResponseDto>(
      `/campaigns/${campaignId}/campaign-users/${campaignUserId}`,
    )

    return response.data.deleted
  }

  const {
    isPending: isDeleteCampaignUserLoading,
    mutateAsync: deleteCampaignUser,
  } = useMutation({
    mutationFn: callDeleteCampaignAdminApi,
    onSuccess: async () => {
      await queryCache.invalidateQueries(
        queryKeys.campaigns.detail(campaignId)._ctx.admins,
      )
    },
  })

  return {
    isDeleteCampaignUserLoading,
    deleteCampaignUser,
  }
}
