import { useMutation, useQueryClient } from '@tanstack/react-query'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { CamelCasedProperties } from 'type-fest'

import {
  CreateCampaignRequestDto,
  CreateCampaignResponseDto,
} from '~shared/types/campaign.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export function useCreateCampaign() {
  const queryCache = useQueryClient()

  const callCreateCampaignApi = async (
    createCampaignData: CamelCasedProperties<CreateCampaignRequestDto>,
  ) => {
    const createCampaignRequest: CreateCampaignRequestDto =
      snakecaseKeys(createCampaignData)

    const response = await ApiService.post<CreateCampaignResponseDto>(
      '/campaigns',
      createCampaignRequest,
    )

    return response.data
      ? camelcaseKeys(response.data, { deep: false })
      : response.data
  }

  const { isPending: isCreateCampaignLoading, mutateAsync: createCampaign } =
    useMutation({
      mutationFn: callCreateCampaignApi,
      onSuccess: async () => {
        await queryCache.invalidateQueries(queryKeys.campaigns.list)
      },
    })

  return {
    isCreateCampaignLoading,
    createCampaign,
  }
}
