import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { CampaignOnboardingJobType } from '~shared/constants/campaign-onboarding'
import { RequestUploadCsvResponseDto } from '~shared/types/campaign-onboarding.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

const MAX_NUM_TRIES = 5

export function useUploadCampaignOnboardingCsv() {
  const queryCache = useQueryClient()

  const uploadCampaignOnboardingCsvFn = async ({
    file,
    campaignId,
    type,
  }: {
    file: File
    campaignId: string
    type: CampaignOnboardingJobType
  }) => {
    if (file.type !== 'text/csv') {
      throw new Error('File must be a CSV')
    }

    // 1. Request upload URL and fields from the backend
    const uploadData = await ApiService.post<RequestUploadCsvResponseDto>(
      `/campaign-onboarding/${campaignId}/request-upload-csv`,
    ).then((res) => res.data)

    let numTries = 0
    while (numTries < MAX_NUM_TRIES) {
      numTries += 1
      try {
        // 2. Upload file directly to image storage service
        const formData = new FormData()
        Object.keys(uploadData.fields).forEach((key) => {
          formData.append(key, uploadData.fields[key])
        })
        formData.append('Content-Type', 'csv')
        formData.append('file', file)
        await axios.post(uploadData.url, formData)

        break
      } catch (e) {
        if (numTries < MAX_NUM_TRIES) {
          continue
        }

        throw new Error(
          'Something went wrong while we were uploading your CSV file. Please try again later, or if the issue persists, contact DistributeSG team for assistance.',
        )
      }
    }

    // 3. Notify backend that upload is complete
    await ApiService.post(
      `/campaign-onboarding/${campaignId}/complete-upload-csv`,
      {
        fileKey: uploadData.key,
        type,
      },
    )
  }

  const {
    mutateAsync: uploadCampaignOnboardingCsv,
    isPending: isUploadingCampaignOnboardingCsv,
  } = useMutation({
    mutationFn: uploadCampaignOnboardingCsvFn,
    onSuccess: async () => {
      await queryCache.invalidateQueries(queryKeys.campaignOnboardingJobs.list)
    },
  })

  return {
    uploadCampaignOnboardingCsv,
    isUploadingCampaignOnboardingCsv,
  }
}
