/**
 * An extensible data table adapted from PaySG:
 * https://github.com/opengovsg/paysg/blob/develop/frontend/src/components/DataTable.tsx
 */
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  type TableCellProps,
  type TableProps,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  HStack,
  Icon,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Button, IconButton, Input } from '@opengovsg/design-system-react'
import {
  type Table as ReactTable,
  Column,
  flexRender,
  Row,
} from '@tanstack/react-table'
import { useRef, useState } from 'react'
import { HiArrowSmDown, HiArrowSmUp } from 'react-icons/hi'

export interface DataTableProps<D> extends TableProps {
  instance: ReactTable<D>
  /**
   * If provided, this number will be used for pagination instead of retrieving
   * from react-table's filtered row count.
   */
  totalRowCount?: number
  isFetching?: boolean
  tablePropOverrides?: {
    [key: string]: {
      td: TableCellProps
    }
  }
  onClickRow?: (data: D) => void
  emptyPlaceholder?: React.ReactElement

  /** options related */
  enableRowEditOptions?: boolean
  UpdateRowModal?: React.ElementType<{
    isOpen: boolean
    onClose: () => void
    confirmUpdate: (data: unknown) => Promise<void> // TODO: not sure how to properly type while keeping it generic
    rowToUpdate: unknown
  }>
  handleUpdateRow?: (row: Row<D>, data: unknown) => Promise<void>
  isUpdatingRow?: boolean
  handleDeleteRow?: (row: Row<D>) => void
  isDeletingRow?: boolean
}

export const DataTable = <T extends object>({
  instance,
  isFetching,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  totalRowCount,
  tablePropOverrides,
  emptyPlaceholder,
  onClickRow,
  enableRowEditOptions,
  UpdateRowModal,
  handleUpdateRow,
  isUpdatingRow,
  handleDeleteRow,
  isDeletingRow,
  ...tableProps
}: DataTableProps<T>): JSX.Element => {
  const { rows } = instance.getRowModel()
  const [hoveredRow, setHoveredRow] = useState<Row<T> | null>(null)
  const [rowToEdit, setRowToEdit] = useState<Row<T> | null>(null)

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose,
  } = useDisclosure()

  return (
    <Flex
      position="relative"
      flexDirection="column"
      flex={1}
      overflowX={{ base: 'hidden', lg: 'auto' }}
      height="100%"
      maxHeight="100%"
      borderRadius="0.25rem"
      boxShadow="sm"
    >
      {handleDeleteRow && isDeletingRow !== undefined && (
        <DeleteAlertDialog
          isOpen={isDeleteModalOpen}
          onClose={onDeleteModalClose}
          confirmDelete={() => {
            rowToEdit && handleDeleteRow(rowToEdit)
            onDeleteModalClose()
          }}
          isDeleting={isDeletingRow}
        />
      )}

      {isUpdateModalOpen &&
        handleUpdateRow &&
        UpdateRowModal &&
        isUpdatingRow !== undefined && (
          <UpdateRowModal
            isOpen={isUpdateModalOpen}
            onClose={onUpdateModalClose}
            confirmUpdate={async (data) => {
              rowToEdit && (await handleUpdateRow(rowToEdit, data))
              onUpdateModalClose()
            }}
            rowToUpdate={rowToEdit}
          />
        )}

      {isFetching && (
        <>
          <Flex
            position="absolute"
            zIndex="1"
            top={0}
            right={0}
            bottom={0}
            left={0}
            // white alpha to denote loading
            padding="1rem"
            background="whiteAlpha.800"
          />
          <Flex
            position="fixed"
            zIndex={2}
            top={0}
            right={0}
            bottom={0}
            left={0}
            width="100vw"
            height="$100vh"
          >
            <Box margin="auto">
              <Spinner />
            </Box>
          </Flex>
        </>
      )}
      <Table sx={{ tableLayout: 'fixed' }} {...tableProps} position="relative">
        <Thead>
          {instance.getHeaderGroups().map((headerGroup) => (
            <Tr
              color="interaction.sub.default"
              borderColor="brand.secondary.100"
              borderBottomWidth="1px"
              data-group
              // To toggle _groupHover styles to show divider when header is hovered.
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => (
                <Th
                  position="relative"
                  textTransform="none"
                  background="white"
                  key={header.id}
                  onClick={
                    header.column.getCanSort()
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                  paddingInline="16px"
                  style={{
                    width: `${header.getSize()}px`,
                  }}
                >
                  <Flex flexDirection="column" gap={2}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {{
                      asc: (
                        <Icon
                          as={HiArrowSmUp}
                          marginLeft="-0.5rem"
                          fontSize="1rem"
                        />
                      ),
                      desc: (
                        <Icon
                          as={HiArrowSmDown}
                          marginLeft="-0.5rem"
                          fontSize="1rem"
                        />
                      ),
                    }[header.column.getIsSorted() as string] ?? null}
                    {header.column.getCanFilter() ? (
                      <Filter column={header.column} />
                    ) : null}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {rows.length === 0 && emptyPlaceholder}
          {rows.map((row) => {
            return (
              <Tr
                borderBottomWidth="1px"
                _hover={
                  onClickRow
                    ? { backgroundColor: 'interaction.muted.main.hover' }
                    : {}
                }
                cursor={onClickRow ? 'pointer' : 'auto'}
                key={row.id}
                onClick={() => onClickRow?.(row.original)}
                {...(enableRowEditOptions
                  ? {
                      onMouseEnter: () => {
                        setHoveredRow(row)
                        setRowToEdit(row)
                      },
                      onMouseLeave: () => setHoveredRow(null),
                    }
                  : {})}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td
                      verticalAlign="center"
                      background="white"
                      key={cell.id}
                      paddingInline="16px"
                      paddingY="14px"
                      textStyle="body-2"
                      height="max-content"
                      {...tablePropOverrides?.[cell.column.id]?.td}
                      {...tablePropOverrides?.[cell.id]?.td}
                    >
                      {cell.column.id === ACTIONS_COLUMN_ID &&
                      hoveredRow?.id === row.id ? (
                        <RowEditOptions
                          openUpdateRowModal={
                            handleUpdateRow ? onUpdateModalOpen : undefined
                          }
                          openDeleteRowModal={
                            handleDeleteRow ? onDeleteModalOpen : undefined
                          }
                        />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Flex>
  )
}

export const ACTIONS_COLUMN_ID = 'actions' as const

type RowEditOptionsProps = {
  openDeleteRowModal: (() => void) | undefined
  openUpdateRowModal: (() => void) | undefined
}

const RowEditOptions = ({
  openDeleteRowModal,
  openUpdateRowModal,
}: RowEditOptionsProps) => {
  return (
    <HStack overflow="visible" spacing={4}>
      {/* Wrapping the icons in an absolute box so that it doesn't cause the table height to expand when hovered  */}
      <Box position="absolute">
        {openUpdateRowModal && (
          <Tooltip label="Edit" placement="top">
            <IconButton
              size="xs"
              fontSize="md"
              variant="clear"
              colorScheme="grey"
              aria-label="update button"
              icon={<EditIcon />}
              onClick={openUpdateRowModal}
            />
          </Tooltip>
        )}
        {openDeleteRowModal && (
          <Tooltip label="Delete" placement="top">
            <IconButton
              size="xs"
              fontSize="md"
              variant="clear"
              colorScheme="grey"
              aria-label="delete button"
              icon={<DeleteIcon />}
              onClick={openDeleteRowModal}
            />
          </Tooltip>
        )}
      </Box>
    </HStack>
  )
}

type DeleteAlertDialogProps = {
  isOpen: boolean
  onClose: () => void
  confirmDelete: () => void
  isDeleting: boolean
}

const DeleteAlertDialog = ({
  isOpen,
  onClose,
  confirmDelete,
  isDeleting,
}: DeleteAlertDialogProps) => {
  const cancelRef = useRef(null)

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Delete entry?</AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to delete this entry? This action is
            irreversible and cannot be undone.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="clear"
              colorScheme="secondary"
              ref={cancelRef}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={confirmDelete}
              isLoading={isDeleting}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

type FilterProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, any>
}

const Filter = ({ column }: FilterProps) => {
  const columnFilterValue = column.getFilterValue()

  return (
    <Input
      type="text"
      variant="flushed"
      size="2xs"
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  )
}
