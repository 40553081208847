import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  CreateLocationRequestDto,
  CreateLocationResponseDto,
} from '~shared/types/location.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export const useAddLocation = (campaignId: string) => {
  const queryClient = useQueryClient()
  const { mutateAsync: addLocation, isPending: isAddingLocation } = useMutation(
    {
      mutationFn: async (params: CreateLocationRequestDto) => {
        const response = await ApiService.post<CreateLocationResponseDto>(
          `/campaigns/${campaignId}/locations`,
          params,
        )

        return response.data
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeys.campaigns.detail(campaignId)._ctx.locations,
        )
        // invalidate query key for location count so that the
        // useLocationCount hook can refetch the latest location count
        await queryClient.invalidateQueries(
          queryKeys.campaigns.detail(campaignId)._ctx.locationCount,
        )
      },
    },
  )

  return {
    addLocation,
    isAddingLocation,
  }
}
