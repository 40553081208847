import {
  Box,
  HStack,
  Link,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Button, Searchbar, SingleSelect } from '@opengovsg/design-system-react'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CampaignIdentifierType } from '~shared/constants/campaign'

import { AdminCampaignLayout } from '~/layouts/AdminCampaignLayout'
import { DistributionView } from '~/types/distribution'

import DistributionDrawer from '../components/DistributionDrawer'
import { EmptyDistributionsCard } from '../components/EmptyDistributionsCard'

import { PaginationNavigation, TableSkeleton } from '~components/DataTable'
import { DistributionsTable } from '~components/DistributionsTable'
import { ExportLoadingModal } from '~components/Modal'
import { DDAction } from '~constants/datadog'
import { useCampaign } from '~hooks/useCampaign'
import { useDistributionCount } from '~hooks/useDistributionCount'
import {
  DistributionSearchParam,
  useDistributions,
} from '~hooks/useDistributions'
import { useExportDistributions } from '~hooks/useExportDistributions'
import { useAuth } from '~lib/auth'
import { addCustomMonitorClick } from '~lib/helpers/monitoring'

const EXPORT_IN_PROGRESS_MODAL_HEADER = 'Export in progress'
const EXPORT_COMPLETED_SUCCESSFULLY_MODAL_HEADER = 'Export complete'
const EXPORT_FAILED_MODAL_HEADER = 'Export failed'

const searchOptions = [
  {
    value: 'identifier',
    label: 'nric'.toUpperCase(),
  },
  {
    value: 'uniqueStringIdentifier',
    label: 'Unique Identifier',
  },
  {
    value: 'address',
    label: 'Address',
  },
  {
    value: 'distributor',
    label: 'Distributor',
  },
  {
    value: 'location',
    label: 'Location',
  },
] as const
// eslint-disable-next-line prettier/prettier
type DistributionSearchOption = typeof searchOptions[number]['value']

export const DistributionsPage = (): JSX.Element => {
  const { user } = useAuth()
  const { campaignId } = useParams() as { campaignId: string }
  const { campaign } = useCampaign(campaignId)
  const limit = 10
  const {
    distributions,
    isDistributionsLoading,
    getNextPageOfDistributions,
    isFetchingDistributionsNextPage,
    getPreviousPageOfDistributions,
    isFetchingDistributionsPreviousPage,
    updateFetchDistributionsSearchQuery,
  } = useDistributions(campaignId, limit)
  const { distributionCount } = useDistributionCount(campaignId)

  const { exporting, exportDistributions, abortController, hasErrorExporting } =
    useExportDistributions(campaign)

  const {
    isOpen: isExportLoadingModalOpen,
    onOpen: onExportLoadingModalOpen,
    onClose: onExportLoadingModalClose,
  } = useDisclosure()

  const handleOnExportButtonClick = async () => {
    onExportLoadingModalOpen()
    // Add custom click action to track which users exported distribution report.
    // Include user id to track back.
    addCustomMonitorClick(DDAction.EXPORT_DISTRITBUTIONS_REPORT, user?.id ?? '')
    await exportDistributions()
  }

  const onCancelButtonClick = () => {
    abortController.current.abort()
    onExportLoadingModalClose()
  }

  const handleOnRetryClick = async () => {
    // Add custom click action to track which users exported distribution report.
    // Include user id to track back.
    addCustomMonitorClick(DDAction.EXPORT_DISTRITBUTIONS_REPORT, user?.id ?? '')
    await exportDistributions()
  }

  const getDefaultSearchOption = (
    campaignType?: CampaignIdentifierType,
  ): DistributionSearchOption => {
    switch (campaignType) {
      case 'nric':
        return 'identifier'
      case 'address':
        return 'address'
      case 'unique_string':
        return 'uniqueStringIdentifier'
      // this case ideally should not happen but if it does
      // the default placeholder will still make sense considering
      // how every campaign regardless of campaign type will contain
      // locations which can be searched by
      default:
        return 'location'
    }
  }
  const defaultSearchOption = getDefaultSearchOption(campaign?.identifierType)

  const searchBarRef = useRef<HTMLInputElement>(null)
  const [isUserSearching, setIsUserSearching] = useState(false)
  const [selectedSearchOption, setSelectedSearchOption] =
    useState<DistributionSearchOption>(defaultSearchOption)
  const debouncedSearch = React.useRef(
    _.debounce((searchParam: DistributionSearchParam | undefined) => {
      updateFetchDistributionsSearchQuery(searchParam)
      setIsUserSearching(false)
    }, 300),
  ).current

  const getSearchParam = (
    searchInput: string,
    selectedSearchOption: DistributionSearchOption,
  ): DistributionSearchParam | undefined => {
    if (searchInput === '') {
      return undefined
    }

    switch (selectedSearchOption) {
      case 'identifier':
        return {
          identifier: searchInput,
        }
      case 'address':
        return {
          address: searchInput,
        }
      case 'distributor':
        return {
          distributorIdentifier: searchInput,
        }
      case 'location':
        return {
          locationName: searchInput,
        }
      case 'uniqueStringIdentifier':
        return {
          uniqueStringIdentifier: searchInput,
        }
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUserSearching(true)
    const searchParam = getSearchParam(e.target.value, selectedSearchOption)
    debouncedSearch(searchParam)
  }

  const changeSearchOption = (option: DistributionSearchOption) => {
    if (searchBarRef.current) {
      searchBarRef.current.value = ''
    }

    setSelectedSearchOption(option)
    updateFetchDistributionsSearchQuery(undefined)
  }

  const searchBarPlaceholderText = () => {
    switch (selectedSearchOption) {
      case 'identifier':
        return 'Search by NRIC'
      case 'address':
        return 'Search by Address'
      case 'uniqueStringIdentifier':
        return 'Search by Unique Identifier'
      case 'distributor':
        return "Search by distributor's email or mobile number"
      case 'location':
        return 'Search by location name'
    }
  }

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const data = distributions ?? []

  const totalDistributionCount = distributionCount ?? 0
  // The boolean value indicates whether the campaign contains
  // distribution data. This is determined by checking if the
  // total distribution count is greater than zero. The distribution
  // data is fetched based on the search parameters and is paginated.
  // Therefore, the count might be 0 if there is no distribution data
  // matching the search parameters.
  const hasDistributionData = totalDistributionCount > 0

  // format distribution count
  const distributionCountString = hasDistributionData
    ? `${Intl.NumberFormat('en').format(totalDistributionCount)} ${
        totalDistributionCount > 1 ? 'distributions' : 'distribution'
      }`
    : ''

  // Distribution side-drawer state
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedDistribution, setSelectedDistribution] =
    useState<DistributionView>()

  const onDrawerOpen = useCallback(() => {
    setIsDrawerOpen(true)
  }, [])

  const onDrawerClose = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  // helper function to get the valid search identitifers that can be used
  // to search distributions for a particular campaignType
  const getValidSearchOptions = (campaignType?: CampaignIdentifierType) => {
    switch (campaignType) {
      case 'nric':
        return searchOptions.filter(
          (searchOption) =>
            searchOption.value !== 'address' &&
            searchOption.value !== 'uniqueStringIdentifier',
        )

      case 'address':
        return searchOptions.filter(
          (searchOption) =>
            searchOption.value !== 'identifier' &&
            searchOption.value !== 'uniqueStringIdentifier',
        )

      case 'unique_string':
        return searchOptions.filter(
          (searchOption) =>
            searchOption.value !== 'identifier' &&
            searchOption.value !== 'address',
        )
      default:
        return searchOptions
    }
  }
  const validSearchOptions = getValidSearchOptions(campaign?.identifierType)

  return (
    <AdminCampaignLayout
      campaignId={campaignId}
      campaignName={campaign?.name}
      sidebarActiveIndex={4}
      campaignStatus={campaign?.status}
    >
      <DistributionDrawer
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        distribution={selectedDistribution}
        campaign={campaign}
      />
      <VStack
        alignItems="stretch"
        spacing={8}
        py={12}
        px={6}
        bg="base.canvas.alt"
        minH="$100vh"
        // take up the remaining width of it's parent HStack
        w="100%"
        // override the base margin
        ml="0px !important"
      >
        <VStack alignItems="stretch">
          <Text textStyle="h3" fontWeight="600">
            Distributions
          </Text>
          {hasDistributionData && (
            <Text textStyle="subhead-1" textColor="brand.secondary.500">
              {distributionCountString}
            </Text>
          )}
        </VStack>
        {hasDistributionData && (
          <HStack justifyContent="space-between">
            <HStack flexGrow={0.2}>
              <HStack flexGrow={1} spacing={-1}>
                <Box flex={3}>
                  <SingleSelect
                    name="searchField"
                    value={selectedSearchOption}
                    items={[...validSearchOptions]}
                    isClearable={false}
                    onChange={(val) =>
                      changeSearchOption(val as DistributionSearchOption)
                    }
                  />
                </Box>
                <Box flex={5}>
                  <Searchbar
                    placeholder={searchBarPlaceholderText()}
                    borderTopLeftRadius={0}
                    borderBottomLeftRadius={0}
                    ref={searchBarRef}
                    defaultIsExpanded
                    showClearButton={false}
                    onChange={handleSearch}
                  />
                </Box>
              </HStack>
            </HStack>
            <Button
              size="sm"
              isLoading={exporting}
              isDisabled={data.length === 0}
              onClick={handleOnExportButtonClick}
            >
              <Text textStyle="subhead-2">Export All</Text>
            </Button>
          </HStack>
        )}
        {isDistributionsLoading || isUserSearching ? (
          <TableSkeleton />
        ) : hasDistributionData ? (
          <DistributionsTable
            data={data}
            campaign={campaign}
            openDrawer={(d: DistributionView) => {
              onDrawerOpen()
              setSelectedDistribution(d)
            }}
          />
        ) : (
          <EmptyDistributionsCard
            // default to false if campaign data is still not fetched
            isCampaignActive={campaign?.status === 'active'}
            campaignId={campaign?.id}
          />
        )}
        <PaginationNavigation
          key={searchBarRef.current?.value}
          onClickNext={getNextPageOfDistributions}
          isLoadingNextPage={isFetchingDistributionsNextPage ?? false}
          onClickPrevious={getPreviousPageOfDistributions}
          isLoadingPreviousPage={isFetchingDistributionsPreviousPage ?? false}
          limit={limit}
        />
      </VStack>

      {/* Modal */}
      <ExportLoadingModal
        isOpen={isExportLoadingModalOpen}
        onClose={onExportLoadingModalClose}
        header={
          exporting
            ? EXPORT_IN_PROGRESS_MODAL_HEADER
            : hasErrorExporting
              ? EXPORT_FAILED_MODAL_HEADER
              : EXPORT_COMPLETED_SUCCESSFULLY_MODAL_HEADER
        }
        content={
          exporting ? (
            <ExportInProgressModalContent />
          ) : (
            <ExportCompleteModalContent
              hasError={hasErrorExporting}
              handleOnRetryClick={handleOnRetryClick}
            />
          )
        }
        primaryButton={
          exporting ? (
            <ExportInProgressModalButton
              onCancelButtonClick={onCancelButtonClick}
            />
          ) : (
            <ExportCompletedModalButton
              onCloseButtonClick={onExportLoadingModalClose}
            />
          )
        }
      />
    </AdminCampaignLayout>
  )
}

const ExportInProgressModalContent = (): JSX.Element => {
  return (
    <VStack spacing={8} pt={10}>
      <Spinner />
      <Text>Your report is being exported and will be ready shortly.</Text>
    </VStack>
  )
}

const ExportInProgressModalButton = ({
  onCancelButtonClick,
}: {
  onCancelButtonClick: () => void
}): JSX.Element => {
  return (
    <Button variant="outline" onClick={onCancelButtonClick}>
      Cancel
    </Button>
  )
}

const ExportCompleteModalContent = ({
  hasError,
  handleOnRetryClick,
}: {
  hasError: boolean
  handleOnRetryClick: () => void
}): JSX.Element => {
  return (
    <VStack alignItems="flex-start">
      {!hasError && (
        <>
          <Text>Your Distributions report has been successfully exported.</Text>
          <Text>
            Please check your downloads folder to find your report. If the file
            is not there, you can{' '}
            <Link onClick={handleOnRetryClick}>try exporting again</Link>.
          </Text>
        </>
      )}
      {hasError && (
        <>
          <Text>Your Distributions report could not be exported.</Text>
          <Text>
            Please <Link onClick={handleOnRetryClick}>try again</Link> or
            contact us if the problem persist.
          </Text>
        </>
      )}
    </VStack>
  )
}

const ExportCompletedModalButton = ({
  onCloseButtonClick,
}: {
  onCloseButtonClick: () => void
}): JSX.Element => {
  return <Button onClick={onCloseButtonClick}>Close</Button>
}
