import { useMutation } from '@tanstack/react-query'

import { CampaignOnboardingJobType } from '~shared/constants/campaign-onboarding'
import { SampleCsvColumnsResponseDto } from '~shared/types/campaign-onboarding.dto'

import { ApiService } from '~lib/helpers/api'

export function useDownloadTemplateCsv() {
  const downloadTemplateCsvFn = async ({
    campaignId,
    type,
  }: {
    campaignId: string
    type: CampaignOnboardingJobType
  }) => {
    const csvColumns = await ApiService.get<SampleCsvColumnsResponseDto>(
      `/campaign-onboarding/${campaignId}/sample-csv-columns`,
      {
        params: {
          type,
        },
      },
    ).then((res) => res.data)

    const csv = csvColumns.join(',')
    const blob = new Blob([csv], { type: 'text/csv' })
    const fileUrl = window.URL.createObjectURL(blob)

    const fileLink = document.createElement('a')
    fileLink.href = fileUrl
    fileLink.download = `sample_${type}.csv`
    fileLink.click()
  }

  const {
    mutateAsync: downloadTemplateCsv,
    isPending: isDownloadingTemplateCsv,
  } = useMutation({
    mutationFn: downloadTemplateCsvFn,
  })

  return {
    downloadTemplateCsv,
    isDownloadingTemplateCsv,
  }
}
