import { CheckIcon } from '@chakra-ui/icons'
import { Box, Text } from '@chakra-ui/react'
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { formatAsAddressString } from '~shared/utils/address'

import { CampaignView } from '~/types/campaign'
import { DistributionView } from '~/types/distribution'

import { StatusBadge } from '~components/Badge/StatusBadge'
import DataTable from '~components/DataTable'
import { NegativeText } from '~components/NegativeText/NegativeText'

interface DistributionsTableProps {
  data: DistributionView[]
  campaign: CampaignView | undefined
  openDrawer: (distribution: DistributionView) => void
}

const columnHelper = createColumnHelper<DistributionView>()

export const DistributionsTable = ({
  data,
  campaign,
  openDrawer,
}: DistributionsTableProps) => {
  const columns = [
    // TODO: investigate why this typescript error occurs, it seems to be fine
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    columnHelper.accessor('identifier', {
      header: () => 'nric'.toUpperCase(),
    }),
    columnHelper.accessor('uniqueStringIdentifier', {
      header: () => 'unique identifier'.toUpperCase(),
    }),
    columnHelper.accessor(
      (row) =>
        formatAsAddressString({
          postalCode: row.postalCode,
          floor: row.floor,
          unit: row.unit,
          streetName: row.streetName,
        }),
      {
        id: 'address',
        header: () => 'address'.toUpperCase(),
      },
    ),
    columnHelper.accessor('locationName', {
      header: () => 'location'.toUpperCase(),
      cell: (info) => {
        return info.getValue() ?? <NegativeText>-</NegativeText>
      },
    }),
    columnHelper.accessor('distributorIdentifier', {
      header: () => 'distributor'.toUpperCase(),
    }),
    {
      // TODO: hard coded until we have a have decided on a way to differentiate
      // between distribution statuses
      header: 'status'.toUpperCase(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (_: CellContext<DistributionView, any>) => {
        return <StatusBadge status="success" text="Distributed" />
      },
    },
    ...(campaign?.products.map((product) => {
      return columnHelper.accessor('products', {
        header: () => (
          <Box textAlign="center">{product.name.toUpperCase()}</Box>
        ),
        cell: (info) => {
          const distributionProduct = info
            .getValue()
            .find((dp) => dp.id === product.id)
          const distributedQuantity = campaign.hasQuantity
            ? distributionProduct?.quantity
            : distributionProduct && <CheckIcon />
          return (
            <Box textAlign="center">
              {distributedQuantity ?? <NegativeText>-</NegativeText>}
            </Box>
          )
        },
        id: product.id,
      })
    }) ?? []),
    columnHelper.accessor('updatedAt', {
      header: () => 'last updated'.toUpperCase(),
    }),
  ]

  const table = useReactTable({
    columns,
    data,
    // From docs (https://tanstack.com/table/v8/docs/api/features/pagination#pagecount), if row is not known `-1` can be set.
    pageCount: -1,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    enableSortingRemoval: false,
    manualSorting: true,
    manualPagination: true,
    enableColumnFilters: false,
    manualFiltering: true,
    enableSorting: false,
    initialState: {
      columnVisibility: {
        identifier: campaign?.identifierType === 'nric',
        uniqueStringIdentifier: campaign?.identifierType === 'unique_string',
        address: campaign?.identifierType === 'address',
      },
    },
    state: {
      sorting: [],
    },
  })

  return (
    <Box>
      {data.length > 0 ? (
        <DataTable instance={table} onClickRow={openDrawer}></DataTable>
      ) : (
        <Text>There are no distributions yet.</Text>
      )}
    </Box>
  )
}
